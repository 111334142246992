@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

*{
  font-family: 'Be Vietnam Pro', sans-serif;
  }
  

/* this is for inputs scroll */
.customScroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 15px;
    height: 4px;
  }
  
  .customScroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
  }
  
  .customScroll::-webkit-scrollbar-thumb {
    background: #d5d5d5;
    border-radius: 15px;
  }
  
  .customScroll::-webkit-scrollbar-thumb:hover {
    background: #98a0a9;
  }
  
/* this is for inputs scroll  */







/* Date İnput icon css below */
input[type="date"]::-webkit-calendar-picker-indicator {
  color: #94a3b8;
  opacity: 1;
  display: block;
  background: url("/public/Calendar1-Outline-24px.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  padding-bottom: 10px;
}
/* Date İnput css above */



/* number input arrow below hide */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
/* number input arrow above hide */


/* selectbox icon remove below*/
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
/* selectbox icon remove above*/

.white-container{
  @apply flex flex-col  rounded-md bg-white px-4 py-4
}

.modal-container{
  @apply flex flex-col px-4 py-4
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

